import { init } from "@web3-onboard/react";

import { CHAIN_ID, COMPANY_NAME, ONBOARD_LOGO, LOGO } from "./config";

import injectedModule from "@web3-onboard/injected-wallets";
import walletConnectModule from "@web3-onboard/walletconnect";
import ledgerModule from "@web3-onboard/ledger";
import gnosisModule from "@web3-onboard/gnosis";

import { getRPCUrl } from "./util";
import { NETWORKS } from "./constants";

const injected = injectedModule();
const ledger = ledgerModule();
const wallet_connect = walletConnectModule({ connectFirstChainId: true });
const gnosis = gnosisModule();

export const NETWORK = NETWORKS[CHAIN_ID];

const web3onboard = init({
    wallets: [injected, ledger, wallet_connect, gnosis],
    chains: [
        {
            id: "0x" + CHAIN_ID.toString(16),
            token: NETWORK.symbol,
            label: NETWORK.network_name,
            rpcUrl: getRPCUrl(),
        },
    ],
    appMetadata: {
        name: `${COMPANY_NAME} vesting`,
        icon: ONBOARD_LOGO || LOGO,
        description: `Where ${COMPANY_NAME} investors can go to claim their tokens`,
    },
    accountCenter: {
        desktop: { enabled: false },
        mobile: { enabled: false },
    },
});

export default web3onboard;
