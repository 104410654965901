import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import { AppProps } from "next/app";

import { Layout } from "@src/components";
import { useCustomClasses } from "@src/hooks";
import { store } from "@src/store";
import { Web3onboardProvider } from "@src/hooks/useWeb3Onboard";
import web3Onboard from "@src/web3onboard";

import "tailwindcss/tailwind.css";
import "@styles/global.scss";
import "@src/app.scss";

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    useCustomClasses();

    useEffect(() => {
        const walletsSub = web3Onboard.state.select("wallets");
        const { unsubscribe } = walletsSub.subscribe((wallets) => {
            const connectedWallets = wallets.map(({ label }) => label);
            window.localStorage.setItem(
                "connectedWallets",
                JSON.stringify(connectedWallets),
            );
        });
        return unsubscribe;
    }, []);

    return (
        <Web3onboardProvider>
            <Provider store={store}>
                <ToastProvider
                    placement="bottom-right"
                    autoDismiss={true}
                    autoDismissTimeout={7000}
                >
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </ToastProvider>
            </Provider>
        </Web3onboardProvider>
    );
}

export default MyApp;
