import React from "react";
import Link from "next/link";

import {
    COMPANY_NAME,
    DISABLE_DECUBATE_FOOTER,
    PRIVACY_POLICY,
    TERMS_AND_CONDITIONS,
    IS_DARK_MODE,
} from "@src/config";

export const Footer: React.FC = () => {
    return (
        <div className="w-full max-w-screen-lg mx-auto">
            {!DISABLE_DECUBATE_FOOTER && (
                <div className="ml-4 md:ml-0 text-left my-10 md:flex justify-center items-center md:space-x-20">
                    <div className="">
                        <p className="mt-2 mb-1 text-secondary">Audited by</p>
                        <img
                            data-testid="audit-img"
                            src={`/img/HaechiLogo-${
                                IS_DARK_MODE ? "Light" : "Dark"
                            }.png`}
                            style={{ width: "150px", height: "22px" }}
                        />
                    </div>
                    <Link href="https://decubate.com">
                        <a
                            target="_blank"
                            className="my-8 sm:my-0"
                            ref="noopener,noreferrer"
                        >
                            <p className="mt-2 text-secondary">Powered by</p>
                            <div className="flex space-x-2 items-center">
                                <img
                                    data-testid="decubate-img"
                                    src={`/img/Logo-Mobile.png`}
                                    style={{ width: "25px", height: "25px" }}
                                />
                                <h3
                                    className="text-2xl"
                                    style={{ fontFamily: "Roboto" }}
                                >
                                    <strong>Decubate</strong>
                                </h3>
                            </div>
                        </a>
                    </Link>
                </div>
            )}
            <div className="border-t-2 border-white border-opacity-10 p-4 flex items-center">
                <div className="flex-1">
                    © COPYRIGHT {new Date().getFullYear()} {COMPANY_NAME} | All
                    rights reserved
                </div>
                <div className="flex items-center space-x-8">
                    {PRIVACY_POLICY && (
                        <Link href={PRIVACY_POLICY}>
                            <a target="_blank" className="hover:underline">
                                Privacy policy
                            </a>
                        </Link>
                    )}
                    {TERMS_AND_CONDITIONS && (
                        <Link href={TERMS_AND_CONDITIONS}>
                            <a
                                target="_blank"
                                ref="noopener,norefferer"
                                className="hover:underline"
                            >
                                Terms &amp; conditions
                            </a>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};
