import { Contract, ContractInterface } from "@ethersproject/contracts";
import { useWeb3Onboard } from "@src/hooks/useWeb3Onboard";

import { useMemo } from "react";

export const useContract = (
    address: string,
    ABI: ContractInterface,
    withSigner = false,
): Contract => {
    const { provider, account } = useWeb3Onboard();
    return useMemo(
        () =>
            !!address && !!ABI && !!provider
                ? new Contract(
                      address,
                      ABI,
                      withSigner
                          ? provider.getSigner(account).connectUnchecked()
                          : provider,
                  )
                : undefined,
        [address, ABI, withSigner, provider, account],
    );
};
