import React from "react";

export interface IButton
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    dark?: boolean;
    loading?: boolean;
}

export const Button: React.FC<IButton> = ({
    className = "",
    children,
    disabled,
    onClick,
    dark,
    loading,
    id,
    ...rest
}) => {
    const classes = dark
        ? `py-2 px-6 rounded focus:outline-none border text-accent border-accent ${className} ${
              !disabled ? "" : "cursor-not-allowed"
          }`
        : `py-2 px-6 rounded focus:outline-none bg-accent text-button ${className} ${
              !disabled ? "" : "cursor-not-allowed opacity-60"
          }`;

    return (
        <button
            disabled={disabled}
            className={classes}
            onClick={(e) => {
                if (!disabled && onClick) {
                    onClick(e);
                }
            }}
            {...rest}
            id={id || "btn"}
            data-testid={id || "btn"}
        >
            {loading ? (
                <div className="loader w-20 mx-auto">
                    <img
                        src="/img/Spinner-Small.png"
                        className="mx-auto"
                        style={{
                            width: "20px",
                            height: "20px",
                        }}
                        alt="Loading..."
                    />
                </div>
            ) : (
                children
            )}
        </button>
    );
};
